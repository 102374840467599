import { ApolloProvider } from "@apollo/react-hooks";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  LanguageIds,
  ValidLanguageCodes,
} from "@rambody/commons/lib/constants";
import { getUserLanguageByIP } from "@rambody/commons/lib/extentions/ip-language-detector";
import "animate.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import PageLoading from "./components/Common/PageLoading";
import AppSettings from "./constants/AppSettings";
import { LanguageCodes } from "./constants/enums";
import ErrorPage from "./pages/ErrorPage";
import NotFoundPage from "./pages/NotFoundPage";
import SharePackage from "./pages/SharePackage";
import TrainerProfile from "./pages/TrainerProfile";
import apolloClient from "./plugins/graphql";
import i18 from "./plugins/i18next";
import "./plugins/swiper";
import { RootDispatcher } from "./store/root-reducer";
import { AppState } from "./store/state";
import { getTheme } from "./theme";

function App(): JSX.Element {
  const { theme, userLanguage } = useSelector((state: AppState) => {
    return {
      theme: state.theme,
      userLanguage: state.userLanguage,
    };
  });

  function getLanguageCode(languageId: LanguageIds): LanguageCodes {
    return Object.keys(LanguageIds).filter(
      (x) => LanguageIds[x as ValidLanguageCodes] === languageId
    )[0] as LanguageCodes;
  }

  const dispatch = useDispatch();
  const rootDispatcher = new RootDispatcher(dispatch);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function changeUserLanguage(): Promise<void> {
      try {
        setLoading(true);
        const savedLang = localStorage.getItem("i18nextLng");
        let language = LanguageCodes.English;
        if (savedLang) {
          language = savedLang as LanguageCodes;
        } else {
          const languageId = await getUserLanguageByIP();
          language = getLanguageCode(languageId);
        }
        rootDispatcher.setLanguageAndTheme(
          AppSettings.DefaultLanguages.find((x) => x.code === language) ||
            AppSettings.DefaultLanguages[0],
          getTheme(language)
        );
        await i18.changeLanguage(language);
      } finally {
        setLoading(false);
      }
    }
    changeUserLanguage();
    // empty array ensures effect is only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div dir={theme.direction}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {loading && <PageLoading />}
          {!loading && (
            <Router>
              <Switch>
                <Route exact path="/error/:errorCode/:message/:operationId?">
                  <ErrorPage />
                </Route>
                <Route exact path="/:trainerId">
                  <TrainerProfile key={userLanguage.code} />
                </Route>
                <Route exact path="/package/:packageId">
                  <SharePackage />
                </Route>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Router>
          )}
        </ThemeProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
